<template>
	<v-col>
		<v-col>
			<v-layout justify-center class="page-title"
				><strong>表格搜尋 Submission Search</strong></v-layout
			>
			<v-layout justify-center>
				<v-card class="sub-dtl-card">
					<v-form ref="form" onSubmit="return false;">
						<v-text-field
							outline
							label="地點編號 Location ID:"
							v-model="location.id"
							:rules="locationRules"
							placeholder="(e.g. 1234567-001)"
							hint="(e.g. 1234567-001)"
							persistent-hint
							@keyup.enter="searchLocation"
						></v-text-field>
						<v-btn
							class="btn-general main-act-btn"
							color="blue"
							@click="searchLocation"
							block
							dark
							>搜尋 Search</v-btn
						>
					</v-form>
				</v-card>
			</v-layout>
		</v-col>
		<!-- Start: Location details -->
		<v-col v-if="!!location_data">
			<v-col>
				<v-divider></v-divider>
			</v-col>
			<v-col>
				<v-layout justify-center>
					<v-card id="location_card" class="sub-dtl-card">
						<v-card-text
							><strong>地點編號 Location ID:</strong><br />
							{{ location_data.location_id }}</v-card-text
						>
						<v-card-text
							><strong>地址 Address:</strong><br />
							<span
								v-html="
									concatAddr(location_data.address_tc, location_data.address_en)
								"
							></span>
						</v-card-text>
						<v-card-text
							><strong>{{locationTypeLabel}}:</strong> <br />{{
								location_data.location_no
							}}</v-card-text
						>
						<v-btn
							class="btn-general btn-create-sub"
							color="blue"
							@click="gotoSubmission('create')"
							block
							dark
							>新增表格 Add new submission</v-btn
						>
					</v-card>
				</v-layout>
			</v-col>
		</v-col>

		<v-col v-if="!!submissions">
			<v-layout justify-center>
				<v-card elevation="0" class="sub-his-tbl">
					<v-card-text
						><span class="tbl-title-txt">Previous Submission</span></v-card-text
					>
					<v-data-table
						class="elevation-1"
						:mobile-breakpoint="0"
						:headers="sub_his_headers"
						:items="submissions"
						dense
						:disable-pagination="true"
						:hide-default-footer="true"
						:items-per-page="-1"
						sortBy="created_date"
						:sortDesc="true"
					>
						<template v-slot:item.created_date="{ item }">
							{{ formatDate(item.created_date, 'DD/MM/YYYY HH:mm:ss') }}
						</template>
						<template v-slot:item.examination_date="{ item }">
							{{ formatDate(item.examination_date, 'DD/MM/YYYY') }}
						</template>
						<template v-slot:item.allow_mod_date="{ item }">
							<a @click="gotoSubmission(getAction(item), item.id)">{{
								getActionTxt(item)
							}}</a>
						</template>
					</v-data-table>
				</v-card>
			</v-layout>
		</v-col>
	</v-col>
	<!-- End: Location details -->
</template>

<script>
import EnginerService from '../service/engineer-service';
import SubmissionService from '../service/submission-service';
import LoginService from '../service/login-service';
import SubmissionConfig from '../config/submission-details.config.js';

const engineerService = new EnginerService();
const submissionService = new SubmissionService();
const loginService = new LoginService();

const moment = require('moment-timezone');

export default {
	name: 'SubmissionSearch',
	data: () => ({
		form: null,
		location: {
			id: null,
		},
		sub_his_headers: [
			{
				value: 'created_date',
				divider: true,
				width: '40%',
				text: '提交日期 Submission Date',
			},
			{
				value: 'examination_date',
				divider: true,
				width: '40%',
				text: '檢查日期 Examination Date',
			},
			{
				value: 'allow_mod_date',
				divider: true,
				width: '20%',
				text: '操作 Action',
			},
		],
		locationRules: [(v) => !!v || '地點編號必須填寫 Location ID is Required'],
		location_data: null,
		submissions: null,
		locationTypeLabel: ''
	}),
	methods: {
		formatDate(dateStr, formatStr) {
			return moment
				.utc(dateStr)
				.tz('Asia/Hong_Kong')
				.format(formatStr);
		},
		getAction(item) {
			if (Date.parse(item.allow_mod_date) > Date.now()) {
				return 'update';
			} else {
				return 'view';
			}
			// console.log(item);
			// return 'view';
		},
		getActionTxt(item) {
			if (Date.parse(item.allow_mod_date) > Date.now()) {
				return '更新 Update';
			} else {
				return '查看 View';
			}
			// console.log(item);
			// return '查看 View';
		},
		concatAddr(addr_tc, addr_en) {
			return addr_tc + '<br/>' + addr_en;
		},
		searchLocation() {
			const valid = this.$refs.form.validate();
			if (valid) {
				if (loginService.isLogin()) {
					let loader = this.$loading.show({
						// Optional parameters
						container: null,
						canCancel: false,
					});
					engineerService
						.searchLocation(this.location.id)
						.then((res) => {
							// this.location_data = res
							// search for sub history

							/*

                            {
                            "success": true,
                            "result": {
                                "id": "string",
                                "location_no": "string",
                                "location_type": 0,
                                "address_en": "string",
                                "address_tc": "string"
                            }
                            }
                        */

							loader.hide();
							// console.log(res.data.result.type);
							// console.log(loginService.getEngineerType());
							if (
								res.data.success &&
								res.data.result.type == loginService.getEngineerType()
							) {
								//verify the location is match with engineer type or not
								this.location_data = res.data.result;								

								this.locationTypeLabel = (this.location_data.type == SubmissionConfig.LOCATION_LIFT_TYPE) ? '升降機編號 Lift No.' : '扶手電梯編號 Escalator No.';								

								//will casue error, need to do it after showing the v-card
								// var container = this.$el.querySelector('#location_card');
								// container.scrollIntoView(true);

								//find the submission
								submissionService
									.getSubmissionsByLocationId(
										loginService.getEngineerNo(),
										this.location_data.location_id,
									)
									.then((res) => {
										// console.log(res);
										if (res.data.success) {
											this.submissions = res.data.result;
										} else {
											//do nothing
										}
									})
									.catch((err) => {
										//do nothing
										console.log(err);
									});
							} else {
								if (res.data.success && res.data.result.type != loginService.getEngineerType()) {
									this.promptSearchFailed(
										"地點與執照類型不相乎。Location does not match with Engineer's license type.",
									);
								} else {
									this.promptSearchFailed('地點不存在。Location not found.');
								}
							}
							// console.log(res);
						})
						.catch((err) => {
							console.log(err);
							this.promptSearchFailed(
								'系統錯誤，請稍後再試！System Error, please try again!',
							);
							loader.hide();
						});
				} else {
					this.promptSearchFailed(
						'登入時效已過，請重新登入！Token expired, please login again!',
					);
					this.$router.push({ path: '/login' });
				}
			}
		},
		promptSearchFailed(msg) {
			const color = 'error';
			this.$emit('snack-bar', {
				color,
				msg,
			});
		},
		gotoSubmission(action, submission_id) {
			switch (action) {
				case 'create':
					this.$router.push(
						'/create_submission/' + this.location_data.location_id,
					);
					break;
				case 'update':
					this.$router.push('/update_submission/' + submission_id);
					break;
				case 'view':
				default:
					this.$router.push('/view_submission/' + submission_id);
					break;
			}
		},
	},
};
</script>

<style lang="scss">
.srh-ttl {
	font-size: x-large;
	padding-bottom: 1rem;
}

.main-act-btn {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-content: center;
}

.sub-his-tbl {
	/*width: 40vh !important;*/
	margin-bottom: 2rem;
}

.tbl-title-txt {
	font-size: small;
	font-weight: bold;
	text-align: left;
}

.translate-down {
	transform: translateY(2rem);
}
</style>
