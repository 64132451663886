var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-col',[_c('v-layout',{staticClass:"page-title",attrs:{"justify-center":""}},[_c('strong',[_vm._v("表格搜尋 Submission Search")])]),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-card',{staticClass:"sub-dtl-card"},[_c('v-form',{ref:"form",attrs:{"onSubmit":"return false;"}},[_c('v-text-field',{attrs:{"outline":"","label":"地點編號 Location ID:","rules":_vm.locationRules,"placeholder":"(e.g. 1234567-001)","hint":"(e.g. 1234567-001)","persistent-hint":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchLocation.apply(null, arguments)}},model:{value:(_vm.location.id),callback:function ($$v) {_vm.$set(_vm.location, "id", $$v)},expression:"location.id"}}),_c('v-btn',{staticClass:"btn-general main-act-btn",attrs:{"color":"blue","block":"","dark":""},on:{"click":_vm.searchLocation}},[_vm._v("搜尋 Search")])],1)],1)],1)],1),(!!_vm.location_data)?_c('v-col',[_c('v-col',[_c('v-divider')],1),_c('v-col',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-card',{staticClass:"sub-dtl-card",attrs:{"id":"location_card"}},[_c('v-card-text',[_c('strong',[_vm._v("地點編號 Location ID:")]),_c('br'),_vm._v(" "+_vm._s(_vm.location_data.location_id))]),_c('v-card-text',[_c('strong',[_vm._v("地址 Address:")]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(
								_vm.concatAddr(_vm.location_data.address_tc, _vm.location_data.address_en)
							)}})]),_c('v-card-text',[_c('strong',[_vm._v(_vm._s(_vm.locationTypeLabel)+":")]),_vm._v(" "),_c('br'),_vm._v(_vm._s(_vm.location_data.location_no))]),_c('v-btn',{staticClass:"btn-general btn-create-sub",attrs:{"color":"blue","block":"","dark":""},on:{"click":function($event){return _vm.gotoSubmission('create')}}},[_vm._v("新增表格 Add new submission")])],1)],1)],1)],1):_vm._e(),(!!_vm.submissions)?_c('v-col',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-card',{staticClass:"sub-his-tbl",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('span',{staticClass:"tbl-title-txt"},[_vm._v("Previous Submission")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.sub_his_headers,"items":_vm.submissions,"dense":"","disable-pagination":true,"hide-default-footer":true,"items-per-page":-1,"sortBy":"created_date","sortDesc":true},scopedSlots:_vm._u([{key:"item.created_date",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_date, 'DD/MM/YYYY HH:mm:ss'))+" ")]}},{key:"item.examination_date",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.examination_date, 'DD/MM/YYYY'))+" ")]}},{key:"item.allow_mod_date",fn:function(ref){
							var item = ref.item;
return [_c('a',{on:{"click":function($event){_vm.gotoSubmission(_vm.getAction(item), item.id)}}},[_vm._v(_vm._s(_vm.getActionTxt(item)))])]}}],null,false,3542519327)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }